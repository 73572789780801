import { useEffect, useState } from 'react';
import { CANDIDATE_EVENT_TYPES } from '../../constants/candidate';

const useParsedCandidateDetails = (candidate, candidateDetailLoading) => {
  const [candidateFullName, setCandidateFullName] = useState('');
  const [candidateProfileInfo, setCandidateProfileInfo] = useState({});
  const [candidateHorse, setCandidateHorse] = useState({});
  const [candidateSummaryDetails, setCandidateSummaryDetails] = useState({});
  const [customerInterest, setCustomerInterest] = useState(null);
  const [isCandidateInterviewed, setIsCandidateInterviewed] = useState(false);

  useEffect(() => {
    if (candidateDetailLoading || !candidate) {
      return;
    }

    setCandidateFullName(`${candidate.firstName} ${candidate.lastName}`);

    setCandidateProfileInfo({
      candidateId: candidate.id,
      avatar: candidate.avatarUrl,
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      email: candidate.email,
      phone: candidate.phoneNumber,
      linkedin: candidate.linkedinUrl,
      concerns: candidate.concerns,
    });

    setCandidateHorse({
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      discardedAt: candidate.discardedAt,
      id: candidate.id,
      events: candidate.events,
    });

    setCandidateSummaryDetails({
      age: candidate.yearOfBirth
        ? new Date().getFullYear() - candidate.yearOfBirth
        : null,
      seniority: candidate.seniority,
      location: candidate.address,
      distance: candidate.drivingDistanceToWorkInKilometers,
      drivingDistanceInMinutes: candidate.drivingTimeToWorkInMinutes,
      drivingDistanceInKilometers: candidate.drivingDistanceToWorkInKilometers,
      motivationToChange: candidate.motivationToChange,
      isActivelySearching: candidate.isActivelySearching,
      currentSelectionProcessesCount: candidate.currentSelectionProcessesCount,
      currentSelectionProcessesNotes: candidate.currentSelectionProcessesNotes,
      currentCompanyName: candidate.currentCompanyName,
      currentAnnualSalary: candidate.currentAnnualSalary,
      desiredAnnualSalary: candidate.desiredAnnualSalary,
      currentBenefits: candidate.currentBenefits,
      additionalCurrentBenefits: candidate.additionalCurrentBenefits,
    });

    setCustomerInterest(candidate.isLikedByClient);

    if (candidate.events && candidate.events.length > 0) {
      setIsCandidateInterviewed(
        candidate.events.some(
          event => event.type === CANDIDATE_EVENT_TYPES.CLIENT_INTERVIEW_DONE,
        ),
      );
    }
  }, [candidate, candidateDetailLoading]);

  return {
    candidateFullName,
    candidateProfileInfo,
    candidateHorse,
    candidateSummaryDetails,
    customerInterest,
    isCandidateInterviewed,
  };
};

export default useParsedCandidateDetails;
