import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import useInitializeApp from '../../utils/hooks/use-initialize-app';
import { Homepage } from '../../pages/Homepage';
import { selectorRootState } from '../../model';
import { PageLogin } from '../../pages/Login';
import { ROUTES } from '../../constants/navigation';
import { PageLoginVerificationCode } from '../../pages/LoginVerificationCode';
import { ErrorScreenGeneric } from '../ErrorScreen';
import JobProgressSwitch from './JobProgressSwitch/JobProgressSwitch';
import CandidateDetail from '../../pages/CandidateDetail';
import { SuccessFee } from '../../pages/SuccessFee';
import { DealRedirectManager } from './DealRedirectManager';
import { SUCCESS_FEE_SEGMENT } from '../../constants/navigation.constants';
import Meta from '../Meta/Meta';
import { useTranslation } from 'react-i18next';
import { jobStoreSelector } from '../../model/Job/Job.selectors';
import { contractStoreStateSelector } from '../../model/Contract/Contract.selectors';

const MainSwitch = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const rootState = useStoreState(selectorRootState);
  const job = useStoreState(jobStoreSelector);
  const contract = useStoreState(contractStoreStateSelector);

  const entity = job || contract;
  const step = t(`general.document_title.${rootState.currentRoute}`);

  useInitializeApp();

  const isLoginPage = useRouteMatch({
    path: [ROUTES.LOGIN, ROUTES.LOGIN_VERIFICATION_CODE],
    exact: true,
  });

  useEffect(() => {
    if (rootState.authenticationError && !isLoginPage) {
      history.push(ROUTES.LOGIN);
    }
  }, [rootState.authenticationError, isLoginPage]);

  if (rootState.appError) {
    return <ErrorScreenGeneric />;
  }

  return (
    <>
      {rootState.currentRoute && entity && (
        <Meta
          title={t('common.meta.titleInternalPages', {
            step,
            entityTitle: entity?.title,
          })}
        />
      )}
      <Switch>
        <Route path="/:dealId(\d+)/:segment?/:applicationId?">
          <DealRedirectManager />
        </Route>

        <Route
          path={[
            '/candidates',
            '/customers/:customerId?',
            '/customers/:customerId?/candidates',
          ]}
        >
          <Homepage />
        </Route>

        <Route path={ROUTES.LOGIN}>
          <Switch>
            <Route path={ROUTES.LOGIN_VERIFICATION_CODE}>
              <PageLoginVerificationCode />
            </Route>
            <Route path="/">
              <PageLogin />
            </Route>
          </Switch>
        </Route>

        <Route
          path={`/jobs/:jobId/candidates/:candidateId/${SUCCESS_FEE_SEGMENT}`}
        >
          <SuccessFee />
        </Route>

        <Route path="/jobs/:jobId/candidates/:candidateId">
          <CandidateDetail />
        </Route>

        <Route path="/:entityType(jobs|contracts)/:entityId/:segment?">
          <JobProgressSwitch />
        </Route>

        <Route index>
          <Homepage />
        </Route>
      </Switch>
    </>
  );
};

export default MainSwitch;
