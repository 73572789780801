import type { FC } from 'react';
import type { TMetaProps } from './Meta.types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Meta: FC<TMetaProps> = props => {
  const { title, description, children } = props;
  const { t } = useTranslation();

  const metaTitle = title ?? t('common.meta.title');
  const metaDescription = description ?? t('common.meta.description');

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {children}
    </Helmet>
  );
};

export default Meta;
