import React from 'react';
import { Pictogram } from '@reverse-hr/pattern-library';
import { BenefitIcons } from '../../../../../constants/candidate';
import { useTranslation } from 'react-i18next';

const Benefits = ({ additionalCurrentBenefits, currentBenefits }) => {
  const { t } = useTranslation();

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--benefits">
      {currentBenefits.map(currentBenefit => (
        <Pictogram
          icon={BenefitIcons[currentBenefit.type]}
          size="small"
          key={currentBenefit.type}
        >
          {t(`showcase.benefitsCategories.${currentBenefit.type}`)}
          {currentBenefit.details ? `: ${currentBenefit.details}` : ''}
        </Pictogram>
      ))}

      {additionalCurrentBenefits && (
        <Pictogram icon="icn-star-24" size="small">
          {additionalCurrentBenefits}
        </Pictogram>
      )}
    </td>
  );
};

export default Benefits;
