import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Pictogram, Range } from '@reverse-hr/pattern-library';
import SummaryCard from '../SummaryCard';
import { BenefitIcons, MotivationIcons } from '../../constants/candidate';
import { Trans, useTranslation } from 'react-i18next';
import { useDrivingTime } from '../../utils/hooks/application/use-driving-time';
import { selectorUser } from '../../model/User';
import { utilityGetTranslatedLocation } from '../../utils/location';
import { jobStoreSelector } from '../../model/Job/Job.selectors';
import PropTypes from 'prop-types';

const SummaryDetails = ({
  age,
  seniority,
  location,
  drivingDistanceInMinutes,
  drivingDistanceInKilometers,
  motivationToChange,
  currentCompanyName,
  isActivelySearching,
  currentSelectionProcessesCount,
  currentSelectionProcessesNotes,
  currentAnnualSalary,
  desiredAnnualSalary,
  currentBenefits,
  additionalCurrentBenefits,
}) => {
  const { t } = useTranslation();
  const job = useStoreState(jobStoreSelector);
  const { maximumSalary, minimumSalary } = job || {};
  const { locale } = useStoreState(selectorUser);
  const drivingTime = useDrivingTime(drivingDistanceInMinutes);

  const parsedDistance =
    typeof drivingDistanceInKilometers === 'number'
      ? drivingDistanceInKilometers.toFixed(0)
      : null;

  const translatedLocation = utilityGetTranslatedLocation({
    language: locale,
    location,
  });

  const locationContent =
    translatedLocation?.city || translatedLocation?.address;

  const rangeRemappedStatuses = {
    left: 'positive',
    right: 'warning',
    center: 'positive',
  };

  const isBenefitsCardVisible =
    currentBenefits?.length > 0 || !!additionalCurrentBenefits;

  return (
    <>
      {!!age ? (
        <SummaryCard modifier="pictogram" title={t('showcase.tableRows.age')}>
          <strong className="u-color-primary-80">{age}</strong>
        </SummaryCard>
      ) : null}

      {!!seniority ? (
        <SummaryCard
          modifier="pictogram"
          title={t('showcase.tableRows.seniority')}
        >
          <strong className="u-color-primary-80">{seniority}</strong>
        </SummaryCard>
      ) : null}

      {!!location ? (
        <SummaryCard
          modifier="multiplepictogram"
          title={t('showcase.tableRows.location')}
        >
          <Pictogram
            icon="icn-map-outline-24"
            modifier="u-color-primary-80"
            size="small"
          >
            <Trans
              context={parsedDistance !== null ? 'withDistance' : ''}
              i18nKey="showcase.location.locationAndDistance"
              values={{
                location: locationContent,
                distance: parsedDistance,
              }}
            />
          </Pictogram>

          {!!drivingTime ? (
            <Pictogram
              icon="icn-clock-24"
              modifier="u-color-primary-80"
              size="small"
            >
              {drivingTime}
            </Pictogram>
          ) : null}
        </SummaryCard>
      ) : null}

      {!!currentAnnualSalary && minimumSalary && maximumSalary ? (
        <SummaryCard title={t('showcase.tableRows.current_salary')}>
          <Range
            min={Math.round(minimumSalary / 1000)}
            max={Math.round(maximumSalary / 1000)}
            value={Math.round(currentAnnualSalary / 1000)}
            mappedStatuses={rangeRemappedStatuses}
          />
        </SummaryCard>
      ) : null}

      {!!desiredAnnualSalary && minimumSalary && maximumSalary ? (
        <SummaryCard title={t('showcase.tableRows.salary')}>
          <Range
            min={Math.round(minimumSalary / 1000)}
            max={Math.round(maximumSalary / 1000)}
            value={Math.round(desiredAnnualSalary / 1000)}
            mappedStatuses={rangeRemappedStatuses}
          />
        </SummaryCard>
      ) : null}

      {!!currentCompanyName ? (
        <SummaryCard
          modifier="pictogram"
          title={t('showcase.tableRows.company')}
        >
          <Pictogram
            icon="icn-building-outline-24"
            modifier="u-color-primary-80"
            size="small"
          >
            <strong>{currentCompanyName}</strong>
          </Pictogram>
        </SummaryCard>
      ) : null}

      {isActivelySearching ? (
        <SummaryCard
          title={t('candidate.sections.summary.otherSelectionsTitle')}
        >
          <p className="typo-text-caption container container--full u-txt-center">
            <strong>
              {t('showcase.otherSelections.currentSelectionsCount', {
                count: currentSelectionProcessesCount,
              })}
            </strong>
            <br />
            {currentSelectionProcessesNotes ?? null}
          </p>
        </SummaryCard>
      ) : null}

      {!!motivationToChange ? (
        <SummaryCard
          modifier="pictogram"
          title={t('showcase.tableRows.motivation')}
        >
          <Pictogram
            icon={MotivationIcons[motivationToChange]}
            modifier="u-color-primary-80"
            size="small"
          >
            <strong>
              {t(`showcase.motivationToChangeValues.${motivationToChange}`)}
            </strong>
          </Pictogram>
        </SummaryCard>
      ) : null}

      {isBenefitsCardVisible && (
        <SummaryCard
          modifier="multiplepictogram"
          title={t('showcase.tableRows.benefits')}
        >
          {currentBenefits.map((benefit, benefitIndex) => (
            <Pictogram
              icon={BenefitIcons[benefit.type]}
              modifier="c-app-card-summary__benefit"
              size="small"
              key={benefitIndex}
            >
              {t(`showcase.benefitsCategories.${benefit.type}`)}
              {benefit.details ? `: ${benefit.details}` : ''}
            </Pictogram>
          ))}

          {!!additionalCurrentBenefits && (
            <Pictogram
              icon="icn-star-24"
              modifier="c-app-card-summary__benefit"
              size="small"
            >
              {additionalCurrentBenefits}
            </Pictogram>
          )}
        </SummaryCard>
      )}
    </>
  );
};

SummaryDetails.propTypes = {
  age: PropTypes.number,
  seniority: PropTypes.string,
  location: PropTypes.object,
  drivingDistanceInMinutes: PropTypes.number,
  drivingDistanceInKilometers: PropTypes.number,
  motivationToChange: PropTypes.string,
  currentCompanyName: PropTypes.string,
  isActivelySearching: PropTypes.bool,
  currentSelectionProcessesCount: PropTypes.number,
  currentSelectionProcessesNotes: PropTypes.string,
  currentAnnualSalary: PropTypes.number,
  desiredAnnualSalary: PropTypes.number,
  currentBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      details: PropTypes.string,
    }),
  ),
};

export default SummaryDetails;
